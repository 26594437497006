import React from 'react';
import { Player } from 'video-react';

import ImageGallery from '../components/ImageGallery';
import Navigation from '../components/Navigation';

import galleryImages from '../assets/gallery';
import modelVideo from '../assets/videos/garden-reel.mp4';
import modelVideoPoster from '../assets/videos/garden-reel-poster.jpg';

interface Props {}
interface State {};

class GalleryPage extends React.Component<Props, State> {
  render () {
    return (
      <>
        <header className="banner banner__no-bg">
          <div className="banner-inner">
            <div className="content-wrapper">
              <h1>Thanet 0 Gauge Group</h1>
              <Navigation />
            </div>
          </div>
        </header>
        <div className="content-wrapper">
          <Player
            playsInline
            preload='metadata'
            poster={modelVideoPoster}
            src={modelVideo}
          />
        </div>
        <div className="content-wrapper">
          <ImageGallery images={galleryImages} />
        </div>
      </>
    )
  }
}

export default GalleryPage;