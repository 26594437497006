import React from 'react';
import { useLocation } from 'react-router-dom';
import { Player } from 'video-react';

import Navigation from '../components/Navigation';

import clubVideo from '../assets/videos/club-reel.mp4';
import clubVideoPoster from '../assets/videos/club-reel-poster.jpg';
import modelShot01 from '../assets/model-shot-01.jpg';
import modelShot02 from '../assets/model-shot-02.jpg';
import modelShot03 from '../assets/model-shot-03.jpg';
import club1 from '../assets/gallery/club-1.jpg';

function HomePage() {  
  const scrolledRef = React.useRef(false);
  const { hash } = useLocation();

  React.useEffect(() => {
    if (hash && !scrolledRef.current) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        scrolledRef.current = true;
      }
    }
  });

  return (
    <>
      <header className="banner" style={{ backgroundImage: `url(${modelShot02})` }}>
        <div className="banner-inner">
          <div className="content-wrapper">
            <h1>Thanet 0 Gauge Group</h1>
            <Navigation />
          </div>
        </div>
      </header>

      
      <div className="content-wrapper">
        <Player
          playsInline
          preload='metadata'
          poster={clubVideoPoster}
          src={clubVideo}
        />
      </div>

      <div className="content-wrapper">
        <p>
          The Thanet 0 Gauge Group was founded more than forty years ago, to give enthusiasts of all things
          "0" Gauge a place to meet and run their locomotives and rolling stock. We have moved on
          considerably since those early days when we met in a local school science laboratory, with a small
          oval double test track.
        </p>
        <p>
          We now have a larger oval test track that is on two levels comprising of a double coarse scale track
          on top with a double fine scale on the lower level.
        </p>
        <p>
          With all four tracks running you can just sit back with a cup of tea, have a chat and watch the trains
          pass by.
        </p>
        <p>
          We cater for all types of "0" gauge from clockwork, coarse scale two and three rail, and two fine
          scale tracks.
        </p>
      </div>

      <div className="image-banner" style={{ backgroundImage: `url(${modelShot03})` }}></div>

      <div className="content-wrapper">
        <div className="two-col">
          <div>
            <h2 id="join-us">Join us</h2>
            <p>
              Join us on the last Friday of each month at:
            </p>
            <address>
              Kearns Hall<br />
              Grenham Bay Ave<br />
              Birchington<br />
              Kent<br/>
              CT7 9NN
            </address>
            <p>
              Our members come from various locations, including Thanet, Dover, Herne Bay, and Canterbury.
            </p>
            <p>
              If you're passionate about 0 gauge locomotives and rolling stock, we extend a warm invitation to join us.
            </p>
            <h2 id="meeting-times">Meeting times 2024</h2>
            <table id="meeting-times">
              <caption className="visually-hidden">Meeting times 2024</caption>
              <tr>
                <th scope="row">April 26th</th>
                <td>
                  <time dateTime="2024-04-26T18:00+0100">18:00 - 21:00</time>
                </td>
              </tr>
              <tr>
                <th scope="row">May 31st</th>
                <td>
                  <time dateTime="2024-05-31T18:00+0100">18:00 - 21:00</time>
                </td>
              </tr>
              <tr>
                <th scope="row">June 28th</th>
                <td>
                  <time dateTime="2024-06-28T18:00+0100">18:00 - 21:00</time>
                </td>
              </tr>
              <tr>
                <th scope="row">July 19th</th>
                <td>
                  <time dateTime="2024-07-19T18:00+0100">18:00 - 21:00</time>
                </td>
              </tr>
              <tr>
                <th scope="row">Aug 30th</th>
                <td>
                  <time dateTime="2024-08-30T18:00+0100">18:00 - 21:00</time>
                </td>
              </tr>
              <tr>
                <th scope="row">Sep 27th</th>
                <td>
                  <time dateTime="2024-09-27T18:00+0100">18:00 - 21:00 </time>
                </td>
              </tr>
              <tr>
                <th scope="row">Oct 25th</th>
                <td>
                  <time dateTime="2024-10-25T16:00+0100">16:00 - 19:00</time>
                </td>
              </tr>
              <tr>
                <th scope="row">Nov 29th</th>
                <td>
                  <time dateTime="2024-11-29T16:00">16:00 - 19:00</time>
                </td>
              </tr>
              <tr>
                <th scope="row">Dec</th>
                <td>
                  No meeting
                </td>
              </tr>
            </table>
          </div>
          <div>
            <iframe
              title="Thanet 0 Gauge Meeting Location"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9961.376781495695!2d1.2899413!3d51.3783518!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d94d08609fdd15%3A0xbd6e1c4ddd01ba2d!2sKearns%20Hall!5e0!3m2!1sen!2suk!4v1696448228814!5m2!1sen!2suk"
              width="100%"
              height="450"
              style={{border:0}}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
      
      <div className="content-wrapper">
        <img src={club1} alt="Club members" />
      </div>

      <div className="banner" style={{ backgroundImage: `url(${modelShot01})` }}>
        <div className="banner-inner">
          <div className="content-wrapper">
            <h2 id="contact">Contact</h2>
            <p>
              Please contact Peter Sandwell at <a href='mailto:thanetogauge@hotmail.com'>thanetogauge@hotmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage;