import React, { useEffect, useState } from 'react';

import dieselTrain from '../assets/diesel-train-sm.png';

function ScrollProgress() {
  const [scrollTop, setScrollTop] = useState<number>(0);

  const onScroll = () => {
    const pageHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolledPercentage = (document.documentElement.scrollTop / pageHeight) * 100;

    setScrollTop(scrolledPercentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
 
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="progressMainWrapper">
      <img 
        src={dieselTrain}
        style={{ left: `${scrollTop}%` }}
        alt=""
       />
    </div>
  )
}

export default ScrollProgress;