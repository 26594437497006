import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import HomePage from './pages/HomePage';
import GalleryPage from './pages/Gallery';
import ScrollProgress from './components/ScrollProgress';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollProgress />
        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route path="/gallery" element={<GalleryPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;